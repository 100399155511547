import { Web3ContextWrapper } from "./Web3Context"
import { UserContextWrapper } from "./UserContext"
import { ApiContextWrapper } from "./ApiContext"
import { Toaster } from "react-hot-toast"
import "../styles/index.css"
import { LoadedContextWrapper } from "./LoadContext"

export const GlobalWrapper = (props) => {
	return (
		<LoadedContextWrapper>
			<Web3ContextWrapper>
				<UserContextWrapper>
					<ApiContextWrapper>
						{props.children}
						<Toaster
							position="bottom-center"
							toastOptions={{
								className: "toast"
							}}
						/>
					</ApiContextWrapper>
				</UserContextWrapper>
			</Web3ContextWrapper>
		</LoadedContextWrapper>
	)
}