const eth = {
	chainId: 1,
	name: "Ethereum",
	currency: "ETH",
	explorerUrl: "https://etherscan.io",
	rpcUrl: "https://cloudflare-eth.com"
}

const bsc = {
	rpcUrl: "https://bsc-dataseed.binance.org/",
	chainId: 56,
	currency: "BNB",
	explorerUrl: "https://bscscan.com",
	name: "Binance Smart Chain"
}

export const chains = {
	eth,
	bsc
}