import { createContext, useContext, useEffect, useState } from "react"
import { api } from "../utils"
import toast from "react-hot-toast"
import { Web3Context } from "./Web3Context"
import { useTranslation } from "react-i18next"

export const UserContext = createContext({
  user: null,
  userStakeData: null,
  userRankData: null,
  token: null,
  transactions: null,
  bonusTransactions: null,
  appliedBonusCode: null,
  updateReferralCode: () => Promise.reject("No context"),
  stakeTokens: () => Promise.reject("No context"),
  unstakeTokens: () => Promise.reject("No context"),
  applyBonusCode: () => Promise.reject("No context"),
  levelUp: () => Promise.reject("No context")
})

export const UserContextWrapper = (props) => {
  const [ user, setUser ] = useState(null)
  const [ userStakeData, setUserStakeData ] = useState(null)
  const [ token, setToken ] = useState(null)
  const [ transactions, setTransactions ] = useState(null)
  const [ bonusTransactions, setBonusTransactions ] = useState(null)
  const [ appliedBonusCode, setAppliedBonusCode ] = useState(null)
  const [ userRankData, setUserRankData ] = useState(null)
  const web3Data = useContext(Web3Context)

  useEffect(() => {
    if (!web3Data.account) {
      setUser(null)
      setToken(null)
      setTransactions(null)
      setBonusTransactions(null)
      setUserStakeData(null)
      setAppliedBonusCode(null)
      setUserRankData(null)
      return
    };
    api.getUser(web3Data.account).then((res) => setUser(res.data))
    api.getUserStakeData(web3Data.account).then((res) => setUserStakeData(res.data))
    api.getUserRanks(web3Data.account).then((res) => setUserRankData(res.data))
    api.getTransactions(web3Data.account).then((res) => setTransactions(res.data))
    api.getBonusTransactions(web3Data.account).then((res) => setBonusTransactions(res.data))
  }, [web3Data.account])

  const { t } = useTranslation()

  const getToken = async () => {
		if (!web3Data.account) {
			throw new Error("Please connect your wallet")
		}
		const nonceRes = await api.getNonce(web3Data.account)
		toast(t("Messages.confirm-message-sign"))
		const signedMessage = await web3Data.signMessage(`Sign this message to verify wallet ownership! ID: ${nonceRes.data.nonce}`)
		const res = await api.postSignedMessage(web3Data.account, signedMessage)
		setToken(res.data.access)
		return res.data.access
	}

	const updateReferralCode = async (newCode) => {
    if (!user || !web3Data.account) throw new Error("Wallet not connected")
    let newToken = token
		if (!newToken || new Date(newToken.expires).getTime() < Date.now()) {
      newToken = await getToken()
      setToken(newToken)
    }
		await api.updateReferralCode(newToken.token, web3Data.account, newCode)
		const res = await api.getUser(web3Data.account)
		setUser(res.data)
	}

  const stakeTokens = async (tokens) => {
    if (!user || !web3Data.account) throw new Error("Wallet not connected")
    let newToken = token
		if (!newToken || new Date(newToken.expires).getTime() < Date.now()) {
      newToken = await getToken()
      setToken(newToken)
    }
		await api.stakeTokens(web3Data.account, tokens, newToken.token)
		const res = await api.getUserStakeData(web3Data.account)
		setUserStakeData(res.data)
	}

  const unstakeTokens = async (tokens) => {
    if (!user || !web3Data.account) throw new Error("Wallet not connected")
    let newToken = token
		if (!newToken || new Date(newToken.expires).getTime() < Date.now()) {
      newToken = await getToken()
      setToken(newToken)
    }
		await api.unstakeTokens(web3Data.account, tokens, newToken.token)
		const res = await api.getUserStakeData(web3Data.account)
		setUserStakeData(res.data)
	}
  
  const applyBonusCode = async (code) => {
		if (!web3Data.connected || !web3Data.account || !user) throw new Error("Please connect your wallet")
    let newToken = token
		if (!newToken || new Date(newToken.expires).getTime() < Date.now()) {
      newToken = await getToken()
      setToken(newToken)
    }
		const res = await api.applyBonusCode(web3Data.account, code, newToken.token)
		setAppliedBonusCode(res.data)
	}

  const levelUp = async () => {
    if (!web3Data.account) throw new Error("Please connect your wallet")
    const res = await api.levelUpUser(web3Data.account)
    setUserRankData(res.data)
  }

  const value = {
    user,
    userStakeData,
    token,
    transactions,
    bonusTransactions,
    appliedBonusCode,
    userRankData,
    updateReferralCode,
    stakeTokens,
    unstakeTokens,
    applyBonusCode,
    levelUp
  }

  return (
    <UserContext.Provider value={value}>
      {props.children}
    </UserContext.Provider>
  )
}