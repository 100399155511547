import { createContext, useEffect, useState } from "react"

import toast from "react-hot-toast"
import { BrowserCrossProvider, CoinbaseCrossProvider, WalletConnectCrossProvider } from "../classes/CrossProvider"
import walletConnectIcon from "../../assets/images/wallet-connect.svg"
import metamaskIcon from "../../assets/images/logo-metamask.png"
import coinbaseIcon from "../../assets/images/coinbase.svg"
import logo from "../../assets/Logo/logo.png"

import { chains, getBrowserProvider, limitDecimals } from "../utils"

export const supportedConnections = [
	{
		label: "Wallet Connect",
		key: "walletconnect",
		iconSrc: walletConnectIcon
	},
	{
		label: "Metamask",
		key: "metamask",
		iconSrc: metamaskIcon,
		hide: () => !window.ethereum
	},
	{
		label: "Coinbase",
		key: "coinbase",
		iconSrc: coinbaseIcon
	}
]

const defaultValue = {
	connected: false,
	account: null,
	connectedConnection: null,
	modalOpen: false,
	connect: () => Promise.reject("No context provided"),
	disconnect: () => Promise.reject("No context provided"),
	sendTransaction: () => Promise.reject("No context provided"),
	signMessage: () => Promise.reject("No context provided"),
	showConnectionModal: () => Promise.reject("No context provided"),
	estimateGasFee: () => Promise.reject("No context provided")
}

export const Web3Context = createContext(defaultValue)

export const Web3ContextWrapper = (props) => {
	const [connected, setConnected] = useState(false)
	const [account, setAccount] = useState(null)
	const [connectedConnection, _setConnectedConnection] = useState(null)
	const [modalOpen, setModalOpen] = useState(false)
	const [provider, setProvider] = useState(null)
	const localConnected = localStorage.getItem("connectedWalletType")
	const [modal, setModal] = useState(null)

	useEffect(() => {
		if (connectedConnection) localStorage.setItem("connectedWalletType", connectedConnection)
		else localStorage.removeItem("connectedWalletType")
	}, [connectedConnection])

	const getModal = async () => {
		const { createWeb3Modal, defaultConfig } = await import("@web3modal/ethers")

		const metadata = {
			name: "Doge Kombat",
			description: "Doge Kombat",
			url: window.location.href,
			icons: [logo]
		}

		const config = defaultConfig({
			metadata
		})

		const modal = createWeb3Modal({
			projectId: "fea7d1fd11941439000227156d5ed0da",
			chains: Object.values(chains),
			ethersConfig: config,
			allowUnsupportedChain: false,
			themeMode: "dark",
			metadata: metadata,
		})
		setModal(modal)
		return modal
	}

	const detectMetamaskConnection = async () => {
		const eth = getBrowserProvider()
		if (!eth) return;
		const accounts = await eth.request({ method: "eth_accounts" })
		if (accounts.length > 0) {
			await disconnect()
			await connectMetamask()
		}
	}

	const detectCoinbaseConnection = async () => {
		await connectCoinbase()
	}

	const detectWalletConnectConnection = async () => {
		const modal = await getModal()
		await new Promise((resolve) => modal.subscribeProvider((e) => {
			if (e.provider) resolve()
		}))

		const provider = modal.getWalletProvider()
		if (!provider) return
		const crossProvider = await WalletConnectCrossProvider.from({
			modal,
			provider
		})
		const account = await crossProvider.getAccount()
		window.gtag?.("event", "wallet")
		window.fbq?.("track", "Lead")
		setProvider(crossProvider)
		setConnected(true)
		setAccount(account)
		setConnectedConnection("walletconnect")
	}

	const setConnectedConnection = (connectedConnection) => {
		_setConnectedConnection(connectedConnection)
	}

	useEffect(() => {
		if (localConnected === "metamask") detectMetamaskConnection()
		else if (localConnected === "walletconnect") detectWalletConnectConnection()
		else if (localConnected === "coinbase") detectCoinbaseConnection()
		// eslint-disable-next-line
	}, [])

	const connectMetamask = async () => {
		let eth = getBrowserProvider()
		if (!eth) return;
		if (eth.providerMap && eth.providerMap.has("MetaMask")) eth = eth.providerMap.get("MetaMask")

		const crossProvider = await BrowserCrossProvider.from(eth)

		const account = await crossProvider.getAccount()

		window.gtag?.("event", "wallet");
		window.fbq?.("track", "Lead")
		setAccount(account)
		setProvider(crossProvider)
		setConnectedConnection("metamask")
		setConnected(true)
		eth.on("accountsChanged", (accounts) => {
			if (accounts.length === 0) return disconnect()
			setAccount(accounts[0])
		})
	}

	const connectCoinbase = async () => {
		try {
			const crossProvider = await CoinbaseCrossProvider.create()
			const account = await crossProvider.getAccount()
			window.gtag?.("event", "wallet");
			window.fbq?.("track", "Lead")
			setConnectedConnection("coinbase")
			setAccount(account)
			setProvider(crossProvider)
			setConnected(true)
			crossProvider.onAccountsChanged((accounts) => {
				if (accounts.length === 0) return disconnect()
				setAccount(accounts[0])
			})
		} catch (e) {
			if (typeof e === "string") toast.error(e)
			setConnectedConnection(null)
			throw e
		}
	}

	const connectWalletConnect = async () => {
		let currModal = modal
		if (!currModal) {
			currModal = await getModal()
			setModal(currModal)
		}
		setConnectedConnection("walletconnect")
		await currModal.open()
		await new Promise(async (resolve, reject) => {
      		const modal = await getModal()
			modal.subscribeProvider(async (e) => {
				if (!e.provider) {
					return
				}
				const crossProvider = await WalletConnectCrossProvider.from({
					modal,
					provider: e.provider
				})
				const account = await crossProvider.getAccount()

				window.gtag?.("event", "wallet");
				window.fbq?.("track", "Lead")
				setProvider(crossProvider)
				setAccount(account)
				setConnected(true)
				resolve()
			})
		})
	}

	const connectFunctions = {
		metamask: connectMetamask,
		walletconnect: connectWalletConnect,
		coinbase: connectCoinbase
	}

	const connect = async (connection) => {
		await connectFunctions[connection]()
		if (!provider) return;
		provider.onDisconnect(disconnect)
	}

	const disconnect = async () => {
		if (provider) await provider.disconnect()
		setProvider(null)
		setAccount(null)
		setConnected(false)
		setConnectedConnection(null)
	}

	const handleErrors = (e) => {
		if (typeof e === "object" && e !== null && "code" in e) {
			if (e.code === "INSUFFICIENT_FUNDS") {
				toast.error("Insufficient funds")
			}
		}
	}

	const sendTransaction = async (args) => {
		args.value = limitDecimals(Number.parseFloat(args.value), (args.decimals ?? 18) - 1)
		if (args.value.includes("e-")) throw new Error("Value too small")
		else if (args.value.includes("e")) throw new Error("Value too large")
		try {
			if (!provider) throw new Error("No provider")
			const chainId = await provider.getChainId()
			const IS_MULTICHAIN = chainId === null
			if (!IS_MULTICHAIN && args.chainId !== chainId) {
				await provider.switchChain(args.chainId)
			}

			if (args.native) {
				const transactionHash = await provider.sendNativeTransaction({
					chainId: args.chainId,
					to: args.to,
					value: args.value,
				})
				return transactionHash
			} else {
				if (!args.abi) throw new Error("No abi provided")
				if (!args.contractAddress) throw new Error("No contract address provided")
				const transactionHash = await provider.transferToContract({
					abi: args.abi,
					contractAddress: args.contractAddress,
					data: {
						to: args.to,
						value: args.value
					},
					decimals: args.decimals ?? 18,
					chainId: args.chainId
				})
				return transactionHash
			}

		} catch (e) {
			handleErrors(e)
			throw e;
		}
	}

	const estimateGasFee = async (tx) => {
		if (!connected) throw new Error("Not connected")
		if (!provider) throw new Error("No provider")
		return await provider.estimateNativeGasFee(tx)
	}

	const signMessage = async (data) => {
		if (!connected) throw new Error("Not connected");
		if (!provider) throw new Error("No provider")
		return provider.signMessage(data)
	}

	useEffect(() => {
		if (account) {
			setModalOpen(false)
		}
	}, [account])

	const Web3Value = {
		account,
		connected,
		connectedConnection,
		modalOpen,
		connect,
		disconnect,
		sendTransaction,
		signMessage,
		showConnectionModal: () => connectWalletConnect(),
		estimateGasFee
	}

	return (
		<Web3Context.Provider value={Web3Value}>
			{props.children}
		</Web3Context.Provider>
	)
}