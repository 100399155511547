import {  createContext, useEffect, useState } from "react"
import { api } from "../utils"

export const ApiContext = createContext({
  stage: null,
  leaderboard: null,
  stakeData: null,
  paymentTokens: null,
  contactModalOpen: false,
  info: null,
  presaleEnded: false,
  setContactModalOpen: () => {},
})

export const ApiContextWrapper= (props) => {
  const [ stage, setStage ] = useState(null)
  const [ leaderboard, setLeaderboard ] = useState(null)
  const [ paymentTokens, setPaymentTokens ] = useState(null)
  const [ contactModalOpen, setContactModalOpen ] = useState(false)
  const [ stakeData, setStakeData ] = useState(null)
  const [ info, setInfo ] = useState(null)

  const [ fetchedStage, setFetchedStage ] = useState(false)

  useEffect(() => {
    api.getActiveStage().then((res) => {
      setFetchedStage(true)
      setStage(res.data ? {
        ...res.data,
        number: Number.parseInt(/\d+/.exec(res.data.stage_name)?.[0] ?? "1")
      } : res.data)
    })
    api.getLeaderboard().then((res) => setLeaderboard(res.data))
    api.getPrices().then((res) => setPaymentTokens(res.data))
    api.getStakeData().then((res) => setStakeData(res.data))
    api.getProjectInfo().then((res) => setInfo(res.data))
    const interval = setInterval(() => api.getPrices().then((res) => setPaymentTokens(res.data)), 60 * 1000)
    return () => clearInterval(interval)
  }, [])

  const value = {
    stage,
    stakeData,
    leaderboard,
    info,
    paymentTokens,
    contactModalOpen,
    setContactModalOpen,
    presaleEnded: fetchedStage && stage === null
  }

  return (
    <ApiContext.Provider value={value}>
      {props.children}
    </ApiContext.Provider>
  )
}