import { cutoffDecimals, getBrowserProvider, getDefaultProvider, getIsMobile, toHex } from "../utils";
import logo from "../../assets/Logo/logo.png"

export class BrowserCrossProvider {
	provider;
	constructor(provider, manualCall = true) {
		if (manualCall) throw new Error("Can't call the constructor directly, use CrossProvider.from() instead")
		this.provider = provider
	}

	static async from(provider) {
		return new BrowserCrossProvider(provider, false)
	}

	async disconnect() {
		await this.provider.removeAllListeners()
	}

	async sendNativeTransaction(tx) {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()
		if (!account) throw new Error("No account selected")
		const transactionHash = await this.provider.request({
			method: "eth_sendTransaction",
			params: [{
				to: tx.to,
				from: account,
				value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18))
			}]
		})
		return transactionHash
	}
	async onDisconnect(callback) {
		this.provider.on("accountsChanged", (accounts) => {
			if (accounts.length === 0) callback()
		})
	}
	async onAccountsChanged(callback) {
		this.provider.on("accountsChanged", callback)
	}
	async onChainChanged(callback) {
		this.provider.on("chainChanged", (chain) => {
			return callback(Number.parseInt(chain, 16))
		})
	}
	async switchChain(chainId) {
		await this.provider.request({
			method: "wallet_switchEthereumChain",
			params: [{ chainId: toHex(chainId) }]
		})
	}
	async getChainId() {
		return Number.parseInt(await this.provider.request({
			method: "eth_chainId",
		}), 16)
	}
	async estimateNativeGasFee(tx) {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()
		const chainId = tx.chainId
		const provider = getDefaultProvider(ethers, chainId)
		const gasLimit = await provider.estimateGas({
			to: tx.to,
			from: account,
			value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18))
		})
		const feeData = await provider.getFeeData()
		return Number.parseFloat(ethers.formatEther((Number(feeData.maxFeePerGas ?? feeData.gasPrice)) * Number(gasLimit) * (chainId === 56 ? 3 : 1.5)))
	}

	async transferToContract(args) {
		const { ethers } = await import("ethers")
		const contract = new ethers.Contract(args.contractAddress, args.abi, this.provider)
		const { data } = args

		const newData = contract.interface.encodeFunctionData("transfer", [data.to, ethers.parseUnits(cutoffDecimals(data.value, args.decimals), args.decimals).toString()])

		const account = await this.getAccount()
		const tx = {
			to: args.contractAddress,
			from: account,
			value: ethers.parseEther("0.000").toString(),
			data: newData
		}
		const transactionHash = await this.provider.request({
			method: "eth_sendTransaction",
			params: [tx]
		})
		return transactionHash
	}
	async signMessage(data) {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()

		data = ethers.hexlify(ethers.toUtf8Bytes(data))

		const signedMessage = await this.provider.request({
			method: "personal_sign",
			params: [data, account]
		})

		return signedMessage
	}

	async getAccount() {
		const accounts = await this.provider.request({
			method: "eth_requestAccounts",
			params: []
		})
		return accounts[0]
	}
}

export class WalletConnectCrossProvider {
	provider;
	modal;
	currentChainId = null;
	constructor(obj, manualCall = true) {
		if (manualCall) throw new Error("Can't call the constructor directly, use CrossProvider.from() instead")
		const provider = obj.provider
		if (!provider) throw new Error("No provider")
		this.provider = provider
		this.modal = obj.modal
		this.currentChainId = obj.modal.getChainId() ?? null
		this.modal.subscribeEvents((e) => {
			if (e.data.event === "SWITCH_NETWORK") {
				this.currentChainId = obj.modal.getChainId() ?? null
			}
		})
	}

	static async from(obj, provider) {
		return new WalletConnectCrossProvider(obj, false)
	}

	async disconnect() {
		this.modal.disconnect()
	}

	async sendNativeTransaction(tx) {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()
		if (!account) throw new Error("No account selected")
		try {
			const transactionHash = await this.provider.request({
				method: "eth_sendTransaction",
				params: [{
					to: tx.to,
					from: account,
					value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18)),
					data: ""
				}]
			})
			return transactionHash
		} catch(e) {
			console.error(e)
			throw e
		}
	}
	async onDisconnect(callback) {
		this.modal.subscribeProvider((state) => {
			if (!state.provider) callback()
		})
	}
	async onAccountsChanged(callback) {
	}
	async onChainChanged(callback) {
		this.modal.subscribeEvents((e) => {
			if (e.data.event === "SWITCH_NETWORK") {
				callback(this.modal.getChainId())
			}
		})
	}
	async switchChain(chainId) {
		await this.provider.request({
			method: "wallet_switchEthereumChain",
			params: [{ chainId: toHex(chainId) }]
		})
	}
	async getChainId() {
		return this.currentChainId
	}

	async estimateNativeGasFee(tx) {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()
		const chainId = await this.getChainId()
		if (!chainId) return 0
		const provider = getDefaultProvider(ethers, chainId)
		const gasLimit = await provider.estimateGas({
			to: tx.to,
			from: account,
			value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18))
		})
		const feeData = await provider.getFeeData()
		return Number.parseFloat(ethers.formatEther((Number(feeData.maxFeePerGas ?? feeData.gasPrice)) * Number(gasLimit) * (chainId === 56 ? 3 : 1.5)))
	}

	async transferToContract(args) {
		const account = await this.getAccount()
		const { ethers } = await import("ethers")
		const contract = new ethers.Contract(args.contractAddress, args.abi)
		const { data } = args

		const newData = contract.interface.encodeFunctionData("transfer", [data.to, ethers.parseUnits(cutoffDecimals(data.value, args.decimals), args.decimals).toString()])

		const tx = {
			to: args.contractAddress,
			from: account,
			value: ethers.parseEther("0.000").toString(),
			data: newData,
		}
		try {
			const transactionHash = await this.provider.request({
				method: "eth_sendTransaction",
				params: [tx]
			})
			return transactionHash
		} catch(e) {
			console.error(e)
			throw e
		}
	}
	async signMessage(data) {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()
		data = ethers.hexlify(ethers.toUtf8Bytes(data))

		const signedMessage = await this.provider.request({
			method: "personal_sign",
			params: [data, account]
		})

		return signedMessage
	}
	async getAccount() {
		return this.modal.getAddress()
	}
}

export class CoinbaseCrossProvider {
	sdk;
	provider;
	constructor(sdk, manualCall = true) {
		if (manualCall) throw new Error("Can't call the constructor directly, use CrossProvider.from() instead")
		this.sdk = sdk
		this.provider = sdk.makeWeb3Provider()
	}

	static async create() {
		const { default : CoinbaseWalletSdk } = await import("@coinbase/wallet-sdk")
		const browserProvider = getBrowserProvider()
		if (getIsMobile() && (!browserProvider?.providerMap || !browserProvider.providerMap.has("CoinbaseWallet")) && !browserProvider?.isCoinbaseBrowser && !browserProvider?.isCoinbaseWallet) {
			throw new Error("Coinbase wallet not injected, use the browser in the coinbase wallet app")
		}
		return new CoinbaseCrossProvider(new CoinbaseWalletSdk({
			appName: "DogeKombat",
			appLogoUrl: logo,
			appChainIds: [1, 56]
		}), false)
	}

	async disconnect() {
		this.provider.disconnect()
	}

	async sendNativeTransaction(tx) {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()
		if (!account) throw new Error("No account selected")
		const transactionHash = await this.provider.request({
			method: "eth_sendTransaction",
			params: [{
				to: tx.to,
				from: account,
				value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18))
			}]
		})
		return transactionHash
	}
	async onDisconnect(callback) {
		this.provider.on("disconnect", callback)
	}
	async estimateNativeGasFee(tx) {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()
		const chainId = tx.chainId
		const provider = getDefaultProvider(ethers, chainId)
		const gasLimit = await provider.estimateGas({
			to: tx.to,
			from: account,
			value: toHex(ethers.parseUnits(tx.value, tx.decimals ?? 18))
		})
		const feeData = await provider.getFeeData()
		return Number.parseFloat(ethers.formatEther((Number(feeData.maxFeePerGas ?? feeData.gasPrice)) * Number(gasLimit) * (chainId === 56 ? 3 : 1.5)))
	}

	async transferToContract(args) {
		const { ethers } = await import("ethers")
		const contract = new ethers.Contract(args.contractAddress, args.abi, getDefaultProvider(ethers, args.chainId))
		const { data } = args

		const newData = contract.interface.encodeFunctionData("transfer", [data.to, ethers.parseUnits(cutoffDecimals(data.value, args.decimals), args.decimals).toString()])

		const account = await this.getAccount()
		const tx = {
			to: args.contractAddress,
			from: account,
			value: ethers.parseEther("0.000").toString(),
			data: newData
		}
		const transactionHash = await this.provider.request({
			method: "eth_sendTransaction",
			params: [tx]
		})
		return transactionHash
	}

	async signMessage(data) {
		const { ethers } = await import("ethers")
		const account = await this.getAccount()

		data = ethers.hexlify(ethers.toUtf8Bytes(data))

		const signedMessage = await this.provider.request({
			method: "personal_sign",
			params: [data, account]
		})

		return signedMessage
	}

	async getAccount() {
		const accounts = await this.provider.request({
			method: "eth_requestAccounts",
			params: []
		})
		return accounts[0]
	}

	async switchChain(chainId) {
		await this.provider.request({
			method: "wallet_switchEthereumChain",
			params: [{ chainId: toHex(chainId) }]
		})
	}
	
	async onChainChanged(callback) {
		this.provider.on("chainChanged", (chain) => {
			return callback(Number.parseInt(chain, 16))
		})
	}

	async onAccountsChanged(callback) {
		this.provider.on("accountsChanged", callback)
	}

	async getChainId() {
		return Number.parseInt(await this.provider.request({
			method: "eth_chainId",
		}), 16)
	}
}