import axios, { AxiosError } from "axios"

const baseUrl = "https://api.presale.gg/v1"

export const apiFetch = async (url, data) => {
	const res = await axios({
		url,
		baseURL: baseUrl,
		...data
	})
	return res
}

const project = "doge-kombat"

export const getActiveStage = () => {
	return apiFetch(`/projects/${project}/stages/current`)
}

export const getProjectInfo = () => {
	return apiFetch(`/projects/${project}/info`)
}

export const getPrices = () => {
	return apiFetch(`/projects/${project}/payment-tokens`)
}

export const getUser = (address) => {
	return apiFetch(`/projects/${project}/wallet/${address}`)
}

export const applyBonusCode = (address, code, accessToken) => {
	return apiFetch(`/projects/${project}/wallet/${address}/bonus-codes/${code}/apply`, {
		method: "POST",
		headers: {
			"Authorization": `Bearer ${accessToken}`
		}
	})
}

export const getUtmArgs = () => {
	const url = new URL(window.location.href)
	return {
		referrer: document.referrer || undefined,
		utm_source: url.searchParams.get("utm_source") ?? undefined,
		utm_medium: url.searchParams.get("utm_medium") ?? undefined,
		utm_campaign: url.searchParams.get("utm_campaign") ?? undefined,
		utm_content: url.searchParams.get("utm_content") ?? undefined,
		utm_term: url.searchParams.get("utm_term") ?? undefined,
	}
}

export const createTransaction = (args) => {
	return apiFetch(`/projects/${project}/wallet/${args.wallet_address}/transactions/nowpayments`, {
		method: "POST",
		data: {
			payment_token_id: args.payment_token_id,
			token_amount: args.token_amount,
			usd_amount: args.usd_amount,
			...getUtmArgs()
		},
	})
}

export const createTransactionMetadata = (address, transactionHash) => {
	return apiFetch(`/projects/${project}/wallet/${address}/transactions/metadata`, {
		method: "POST",
		data: {
			transaction_hash: transactionHash,
			...getUtmArgs()
		}
	})
}

export const getLeaderboard = () => {
	return apiFetch(`/projects/${project}/leaderboard`)
}

export const getNonce = (walletAddress) => {
	return apiFetch(`/projects/${project}/wallet/${walletAddress}/nonce`)
}

export const postSignedMessage = (walletAddress, signedMessage) => {
	return apiFetch(`/projects/${project}/wallet/${walletAddress}/signature`, {
		method: "POST",
		data: {signature: signedMessage}
	})
}

export const updateReferralCode = (token, address, referralCode) => {
	return apiFetch(`/projects/${project}/wallet/${address}`, {
		method: "PATCH",
		data: {referral_code: referralCode},
		headers: {
			"Authorization": `Bearer ${token}`
		}
	})
}

export const getStakeData = () => {
	return apiFetch(`/projects/${project}/stakes`)
}

export const getUserStakeData = (address) => {
	return apiFetch(`/projects/${project}/wallet/${address}/stakes`)
}

export const stakeTokens = (address, numTokens, accessToken) => {
	return apiFetch(`/projects/${project}/wallet/${address}/stakes`, {
		method: "POST",
		data: {tokens: numTokens},
		headers: {
			"Authorization": `Bearer ${accessToken}`
		}
	})
}

export const unstakeTokens = (address, numTokens, accessToken) => {
	return apiFetch(`/projects/${project}/wallet/${address}/unstake`, {
		method: "POST",
		data: {tokens: numTokens},
		headers: {
			"Authorization": `Bearer ${accessToken}`
		}
	})
}

export const getTransactions = (address) => {
	return apiFetch(`/projects/${project}/wallet/${address}/transactions`, {
		method: "GET"
	})
}

export const getBonusTransactions = (address) => {
	return apiFetch(`/projects/${project}/wallet/${address}/bonus-transactions`, {
		method: "GET"
	})
}

export const getUserRanks = async (address) => {
	return apiFetch(`/projects/${project}/wallet/${address}/levels`)
}

export const levelUpUser = (address) => {
	return apiFetch(`/projects/${project}/wallet/${address}/levels`, {method: "POST"})
}

export const getApiErrorMessage = (e, defaultMsg) => {
	if (e instanceof AxiosError) {
		return getApiErrorMessage(e.response?.data)
	}
	if (typeof(e) === "string") return e;
	if (e && typeof(e) === "object" && "message" in e) {
		if (e.message && typeof(e.message) === "string") {
			return e.message
		}
	}
	return defaultMsg ?? "Internal server error"
}