import { createContext, useState } from "react";

export const LoadContext = createContext({
	queryParamsLoaded: false,
	setQueryParamsLoaded: () => {}
})

export const LoadedContextWrapper = (props) => {
	const [ queryParamsLoaded, setQueryParamsLoaded ] = useState(false)

	const value = {
		queryParamsLoaded,
		setQueryParamsLoaded
	}

	return (
		<LoadContext.Provider value={value}>
			{props.children}
		</LoadContext.Provider>
	)
}