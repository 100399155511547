import erc20Abi from "../abi/erc20.json"
import bep20Abi from "../abi/bep20.json"

export const PAYMENT_WALLET= "0x59516b29A02F5f801d3c87aF4EAe07ba775c429F"

export const getSignerFromOptions = async (
	provider,
	options,
	library
) => {
	const { ethers } = await import("ethers")
	const privateKey = options && 'privateKey' in options && options.privateKey
	const json = options && 'json' in options && options.json
	const password = options && 'password' in options && options.password

	const privateKeySigner = privateKey && provider && new ethers.Wallet(privateKey, provider)
	const encryptedJsonSigner =
	json && password && provider && ethers.Wallet.fromEncryptedJsonSync(json, password).connect(provider)

	const optionsSigner = options && 'signer' in options && options.signer

	return privateKeySigner || encryptedJsonSigner || optionsSigner || library?.getSigner()
}

const rpcMap = {
	"1": "https://rpc.ankr.com/eth",
	"56": "https://bsc-dataseed1.binance.org/"
}

export const getBalanceOfWalletAddress = async (
	walletAddress,
	chainId,
	tokenContractAddress,
	decimals
) => {
	const { ethers } = await import("ethers")
	const provider = ethers.getDefaultProvider(rpcMap[chainId.toString()])

	if (tokenContractAddress) {
		const abi = getAbi(chainId)
    if (!abi) throw new Error("Invalid abi")
		const contract = new ethers.Contract(tokenContractAddress, abi, provider)
		const balance = await contract.balanceOf(walletAddress)
		return ethers.formatUnits(balance, decimals ?? 18)
	}
	const balance = await provider.getBalance(walletAddress)
	return ethers.formatUnits(balance, decimals ?? 18)
}

export const getDefaultProvider = (ethers, chainId) => {
	return ethers.getDefaultProvider(rpcMap[chainId.toString()])
}

export const getChainIdFromLabel = (label) => {
	return {
		"ERC-20": 1,
		"BEP-20": 56
	}[label.toUpperCase()] ?? null
}

export const isCurrencyNative = (symbol, chainId) => {
	return {
		1: "ETH",
		56: "BNB"
	}[chainId] === symbol.toUpperCase()
}

export const getAbi = (chainId) => {
	return {
		1: erc20Abi,
		56: bep20Abi
	}[chainId] ?? null
}

export const getContractAddress = (symbol) => {
	return {
		"USDT": "0xdAC17F958D2ee523a2206206994597C13D831ec7"
	}[symbol.toUpperCase()] ?? null
}

export const getDecimals = (symbol) => {
	return {
		"USDT": 6
	}[symbol.toUpperCase()] ?? null
}

export const getBrowserProvider = () => {
	return window.ethereum
}

export const cutoffDecimals = (num, decimals) => {
	const index = num.indexOf(".")
	if (index === -1) return num
	const numDecimals = num.length - 1 - index
	if (numDecimals <= decimals) return num
	return num.substring(0, index + decimals)
}